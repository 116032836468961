import { object, string, ref } from 'yup'
import { t } from '@lingui/macro'

const email = (i18n) =>
  object().shape({
    email: string()
      .email(i18n._(t`Pastikan alamat email valid!`))
      .required(i18n._(t`Alamat email harus diisi!`))
  })

const changeEmail = (i18n) =>
  object().shape({
    confirmEmail: string().oneOf(
      [ref('Email')],
      i18n._(t`Konfirmasi email tidak sama dengan email yang kamu masukkan...`)
    )
  })

const profile = (i18n, withPageField, withPrivateField) => {
  return object()
    .shape({
      name: string()
        .max(60, i18n._(t`Nama maksimal 60 karakter`))
        .required(i18n._(t`Nama harus diisi!`)),
      ...(withPageField && {
        title: string()
          .max(120, i18n._(t`Title maksimal 120 karakter`))
          .transform((v) => v || null),
        description: string()
          .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
          .transform((v) => v || null)
          .nullable(true),
        photoURL: string()
          .transform((value) => value || null)
          .required(
            i18n._(t`Pastikan gambar selesai diupload sebelum submit!`)
          ),
        backgroundURL: string()
          .transform((value) => value || null)

          .required(i18n._(t`Pastikan gambar selesai diupload sebelum submit!`))
      }),
      ...(withPrivateField && {
        phone: string()
          .matches(/^[0-9+]+$/, i18n._('Pastikan nomor telepon valid!'))
          .min(8, i18n._('Nomor telepon minimal 8 karakter'))
          .max(15, i18n._('Nomor telepon maksimal 15 karakter'))
          .transform((v) => v || null)
          .nullable(true)
      })
    })
    .noUnknown(true)
}

const newPassword = (i18n) =>
  object().shape({
    password: string()
      .min(8, i18n._(t`Password minimal 8 karakter!`))
      .max(32, i18n._(t`Password maksimal 32 karakter!`))
      .test({
        name: 'contains-uppercase',
        message: i18n._(t`Password harus mengandung minimal 1 huruf kapital`),
        test: (value) => /[A-Z]/.test(value)
      })
      .test({
        name: 'contains-lowercase',
        message: i18n._(t`Password harus mengandung minimal 1 huruf kecil`),
        test: (value) => /[a-z]/.test(value)
      })
      .test({
        name: 'contains-number',
        message: i18n._(t`Password harus mengandung minimal 1 angka`),
        test: (value) => /[0-9]/.test(value)
      })
      .required('Password is required'),
    confirmPassword: string().oneOf(
      [ref('password')],
      i18n._(
        t`Konfirmasi password tidak sama dengan password yang kamu masukkan...`
      )
    )
  })

const changePassword = (i18n) =>
  newPassword(i18n).concat(
    object().shape({
      oldPassword: string().required(i18n._(t`Password lama harus diisi!`))
    })
  )

const signIn = (i18n) =>
  email(i18n).concat(
    object().shape({
      // intentionally used different schema to obscure password format
      password: string().required(i18n._(t`Password harus diisi!`))
    })
  )

const signUpGuest = (i18n) =>
  email(i18n)
    .concat(
      object().shape({
        name: string()
          .max(60, i18n._(t`Nama maksimal 60 karakter`))
          .nullable()
          .transform((v) => v || null),
        phone: string()
          .matches(/^[0-9+]+$/, i18n._('Pastikan nomor telepon valid!'))
          .min(8, i18n._('Nomor telepon minimal 8 karakter'))
          .max(15, i18n._('Nomor telepon maksimal 15 karakter'))
          .transform((v) => v || null)
          .nullable(true)
      })
    )
    .noUnknown(true)

const signUp = (i18n) => signUpGuest(i18n).concat(newPassword(i18n))

const UserSchema = {
  signIn,
  signUpGuest,
  signUp,
  //
  email,
  changeEmail,
  //
  newPassword,
  changePassword,
  // profile related
  profile
}

export default UserSchema
